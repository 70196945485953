import React from 'react';
import { Global, css, useTheme } from '@emotion/react';
import reset from './reset';
import global from './global';
import nprogress from './nprogress';

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        ${reset}
        ${global(theme)}
        ${nprogress(theme)}
      `}
    />
  );
};

export default GlobalStyle;
