import getConfig from '../utils/get-config';
import _consts from './_consts';

export default {
  consts: { ..._consts },
  site: {
    background_color: getConfig('STYLE_BACKGROUND_COLOR'),
    line_color: getConfig('STYLE_SITE_LINE_COLORS'),
    line_color_light: getConfig('STYLE_CARD_SECTION_SPACER_COLOR')
  },
  colors: {
    primary: getConfig('STYLE_PRIMARY_COLOR'),
    secondary: getConfig('STYLE_SECONDARY_COLOR'),
    link: getConfig('STYLE_LINK_COLOR')
  },
  shared_layer_banner: {
    background_color: getConfig('STYLE_SHARED_LAYER_BANNER_BACKGROUND_COLOR'),
    background_gradient: getConfig(
      'STYLE_SHARED_LAYER_BANNER_BACKGROUND_GRADIENT'
    ),
    text_color: getConfig('STYLE_SHARED_LAYER_BANNER_TEXT_COLOR')
  },
  header: {
    background_color: getConfig('STYLE_HEADER_BACKGROUND_COLOR'),
    border_color: getConfig('STYLE_HEADER_BORDER_COLORS'),
    text_color: getConfig('STYLE_HEADER_LINK_COLOR'),
    hover: {
      background_color: getConfig('STYLE_HEADER_HOVER_BACKGROUND_COLOR'),
      text_color: getConfig('STYLE_HEADER_HOVER_TEXT_COLOR')
    },
    logo: {
      height: getConfig('STYLE_LOGO_HEIGHT')
    },
    title: {
      color: getConfig('STYLE_BRAND_COLOR')
    }
  },
  hero: {
    text_color: getConfig('STYLE_HERO_TEXT_COLOR'),
    overlay: {
      background_color: getConfig('STYLE_HERO_BACKGROUND_COLOR'),
      background_gradient: getConfig('STYLE_HERO_BACKGROUND_GRADIENT'),
      opacity: getConfig('STYLE_HERO_BACKGROUND_OPACITY')
    }
  },
  footer: {
    border_color: getConfig('STYLE_SITE_LINE_COLORS')
  },
  card: {
    padding: getConfig('STYLE_CARD_PADDING'),
    border_color: getConfig('STYLE_CARD_SPACER_COLOR')
  },
  loading_bar: {
    background_color: getConfig('STYLE_LOADING_BAR_BACKGROUND'),
    foreground_color: getConfig('STYLE_LOADING_BAR_FOREGROUND')
  },
  button: {
    primary: {
      background_color: getConfig('STYLE_BUTTON_PRIMARY_BACKGROUND_COLOR'),
      border_color: getConfig('STYLE_BUTTON_PRIMARY_BORDER_COLOR'),
      text_color: getConfig('STYLE_BUTTON_PRIMARY_TEXT_COLOR'),
      hover: {
        background_color: getConfig(
          'STYLE_BUTTON_PRIMARY_HOVER_BACKGROUND_COLOR'
        ),
        border_color: getConfig('STYLE_BUTTON_PRIMARY_HOVER_BACKGROUND_COLOR'),
        text_color: getConfig('STYLE_BUTTON_PRIMARY_HOVER_TEXT_COLOR')
      }
    },
    secondary: {
      background_color: getConfig('STYLE_BUTTON_BACKGROUND_COLOR'),
      border_color: getConfig('STYLE_BUTTON_BORDER_COLOR'),
      text_color: getConfig('STYLE_BUTTON_TEXT_COLOR'),
      hover: {
        background_color: getConfig('STYLE_BUTTON_HOVER_BACKGROUND_COLOR'),
        border_color: getConfig('STYLE_BUTTON_HOVER_BACKGROUND_COLOR'),
        text_color: getConfig('STYLE_BUTTON_HOVER_TEXT_COLOR')
      }
    }
  },
  input: {
    border_color: getConfig('STYLE_BUTTON_BORDER_COLOR')
  },
  checkbox: {
    background_color: 'transparent',
    border_color: getConfig('STYLE_BUTTON_PRIMARY_BORDER_COLOR'),
    checked: {
      background_color: getConfig('STYLE_BUTTON_PRIMARY_BACKGROUND_COLOR'),
      border_color: getConfig('STYLE_BUTTON_PRIMARY_BACKGROUND_COLOR'),
      text_color: getConfig('STYLE_BUTTON_PRIMARY_TEXT_COLOR')
    }
  },
  textarea: {
    border_color: getConfig('STYLE_CARD_SECTION_SPACER_COLOR'),
    focus: {
      border_color: getConfig('STYLE_SITE_LINE_COLORS')
    }
  },
  toggle_switch: {
    circle_color: '#ffffff',
    focus_outline_color: 'blue',
    checked: {
      background_color: '#3AC962'
    },
    unchecked: {
      background_color: '#C9423A'
    }
  },
  search: {
    colors: {
      col1: '#6dd0fa',
      col2: '#68ce6c',
      col3: '#e6b0f3',
      col4: '#f8ac53',
      col5: '#fdd134',
      col6: '#fa7e6d',
      col7: '#6d6dfa',
      col8: '#fa6dc2',
      col9: '#438e35'
    }
  },
  dropdown: {
    background_color: getConfig('STYLE_FACET_MENU_BACKGROUND_COLOR'),
    border_color: getConfig('STYLE_SITE_LINE_COLORS'),
    hover: {
      background_color: getConfig('STYLE_FACET_MENU_HOVER_BACKGROUND_COLOR')
    }
  },
  notice: {
    success_color: '#13b579',
    warning_color: '#ffc022'
  },
  breadcrumbs: {
    background_color: getConfig('STYLE_BREADCRUMBS_BACKGROUND_COLOR')
  },
  cookie_notice: {
    background_color: '#231e1e',
    text_color: '#ffffff',
    link_color: '#35f5ff',
    navigation: {
      active: {
        background_color: '#231e1e',
        text_color: '#ffffff'
      },
      default: {
        background_color: '#373737',
        text_color: '#ffffff'
      }
    },
    button: {
      primary: {
        background_color: '#ffffff',
        text_color: '#231e1e'
      },
      secondary: {
        background_color: '#231e1e',
        text_color: '#ffffff'
      }
    }
  }
};
