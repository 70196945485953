import Router from 'next/router';

const redirect = (page, ctx) => {
  if (ctx && ctx.res) {
    ctx.res.writeHead(302, { Location: `${page}` });
    ctx.res.end();
  } else if (process.browser) {
    Router.push(`${page}`);
  } else if (process.env.NODE_ENV !== 'test')
    // eslint-disable-next-line no-console
    console.warn(`[ERROR] Cannot Redirect() to ${page}`);
};

export default redirect;
