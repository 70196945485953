/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/forbid-prop-types */
import axios from 'axios';
import PropTypes from 'prop-types';
import getConfig from '../get-config';
import { getCookie, setCookie } from '../cookies';
import redirect from '../redirect';
import parseCookies from '../parse-cookies';

// Returns the full API URL
export const getAPIUrl = (path, uuid) => {
  const isDev = getConfig('IS_DEV');
  const apiUrl = getConfig('API_URL');
  const formattedApiUrl = apiUrl.replace('{uuid}', uuid);
  let reqUrl = path;

  if (path[0] === '?') reqUrl = apiUrl.replace('{uuid}', path);
  if (path[0] === '/') reqUrl = `${formattedApiUrl}${path}`;

  // If we are on dev, use the /api/* server as a proxy to bypass CORS
  if (isDev)
    reqUrl = `http://localhost:3000/api?url=${encodeURIComponent(reqUrl)}`;

  return reqUrl;
};

// Wrapper for HTTP requests (using Axios)
const HTTPRequest = async ({ method, url, uuid, formData, ctx }) => {
  const reqUrl = getAPIUrl(url, uuid);
  const token = getCookie('jwt', ctx);

  try {
    // Format the fields
    const fields = {
      method,
      url: reqUrl,
      headers: {},
      withCredentials: url[0] === '/'
    };
    // We have to append this separately, or GET requests will fail (even with an empty body!)
    if (formData) fields.data = formData;

    // Append the JWT as a header, if we're requesting an API route
    if (token && url[0] === '/')
      fields.headers.Authorization = `Bearer ${token}`;

    // Make the request
    const { data } = await axios(fields);

    // Return the data
    return Promise.resolve(data);
  } catch (error) {
    // A network error prevented the request reaching the API
    if (!error.response) return Promise.reject('error.network');

    // The server responded with a status code that falls out of the range of 2xx
    if (error.response) {
      // Did we get a 401 from an API endpoint other than '/login'?
      if (
        error.response.status === 401 &&
        url.indexOf('/login') === -1 &&
        (!ctx || !ctx.asPath.includes('/login')) &&
        url[0] === '/'
      ) {
        try {
          // Get the refresh token, and set the config object
          const refreshCookie = getCookie('refresh', ctx);
          const config = { withCredentials: true };
          if (refreshCookie)
            config.headers = { Authorization: `Bearer ${refreshCookie}` };

          // Attempt to refresh the user's JWT
          const res = await axios.post(
            getAPIUrl('/users/refresh', uuid),
            null,
            config
          );

          // Update the response to have the new cookies
          const cookies = parseCookies(res);
          const cookieVals = ['jwt', 'refresh', '_hyku_session'];
          cookieVals.forEach(name => {
            const cookie = cookies[name];
            if (cookie) setCookie(name, cookie.value, { ...cookie, ctx });
          });

          // Return the original request if we are on the browser
          if (process.browser)
            return await HTTPRequest({ method, url, uuid, formData, ctx });

          // Reload the page if we are on the server
          return redirect(ctx.asPath || '/', ctx);
        } catch (err) {
          // Remove the cookies (as a precaution)
          const cookies = parseCookies(err.response);
          const cookieVals = ['jwt', 'refresh', '_hyku_session'];
          cookieVals.forEach(name => {
            const cookie = cookies[name];
            if (cookie) setCookie(name, cookie.value, { ...cookie, ctx });
          });

          // Redirect user to the login page
          return redirect(
            `/login?from=${ctx.asPath || '/'}&reason=session_expired`,
            ctx
          );
        }
      }

      // Handle other codes
      if (error.response.status && error.response.status === 404)
        return Promise.reject('404');
      if (error.response.data) return Promise.reject(error.response.data);
      if (error.response.status) return Promise.reject(error.response.status);
    }

    // Unknown error occurred
    // eslint-disable-next-line no-console
    if (!process.env.NODE_ENV === 'test') console.error(error);
    return Promise.reject('error.network');
  }
};

HTTPRequest.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  formData: PropTypes.object
};
HTTPRequest.defaultProps = {
  uuid: null,
  formData: null
};

export default HTTPRequest;
