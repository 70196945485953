import { HYDRATE } from 'next-redux-wrapper';
import TenantsActionTypes from './types';

const INITIAL_STATE = {
  data: null
};

const TenantsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return { ...state, ...payload.tenants };
    case TenantsActionTypes.GET_TENANTS_DATA:
      return {
        ...INITIAL_STATE,
        data: payload
      };
    default:
      return state;
  }
};

export default TenantsReducer;
