import React, { useState } from 'react';
import PropTypes from 'prop-types';

const InitError = ({ nonce, message }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const onDetailsClick = () => {
    if (message !== null) setDetailsOpen(!detailsOpen);
  };

  return (
    <main id='error'>
      <div className='wrapper'>
        <h1 id='title'>Sorry, something went wrong.</h1>
        <p className='message'>
          An unexpected error has prevented this page from loading. Try
          reloading the page, or check back later.
        </p>
        <ul className='options'>
          <li>
            <a href='/'>
              <div className='button'>Reload</div>
            </a>
          </li>
          <li>
            <button
              className='invert'
              type='button'
              disabled={message === null}
              id='details-button'
              aria-expanded={detailsOpen}
              aria-controls='moreinfo'
              aria-disabled={message === null}
              onClick={onDetailsClick}
            >
              Details
            </button>
          </li>
        </ul>
        {message !== null && (
          <div
            className={`details ${!detailsOpen ? 'hidden' : ''}`}
            id='moreinfo'
            role='region'
            aria-labelledby='details-button'
          >
            <h2 className='details-title'>
              The following message was provided by the server:
            </h2>
            <div className='details-message'>{message}</div>
          </div>
        )}
      </div>

      <style jsx='true' nonce={nonce}>{`
        #error {
          box-sizing: border-box;
          padding: 10em 0;
          text-align: center;
          font-family: PingFang HK, -apple-system, BlinkMacSystemFont,
            'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        }
        @media (max-width: 780px) {
          #error {
            padding: 5em 0;
          }
        }
        @media (max-width: 450px) {
          #error {
            padding: 1em;
          }
        }

        .wrapper {
          max-width: 25em;
          margin: 0 auto;
        }

        #title {
          font-size: 1.3em;
        }

        .message {
          line-height: 1.5em;
        }

        .options {
          display: flex;
          justify-content: space-between;
          margin: 3rem 0 0 0;
          list-style-type: none;
          padding: 0;
        }

        a {
          text-decoration: none;
          display: block;
          width: 100%;
          height: 100%;
        }

        button,
        .button {
          padding: 0.4em 0.7em;
          border-radius: 0.15em;
          font-size: 0.9em;
          background: #616161;
          color: #ffffff;
          cursor: pointer;
          text-transform: uppercase;
          border: none;
          text-decoration: none;
        }

        button:disabled,
        .button:disabled {
          cursor: not-allowed;
        }
        button:disabled:hover,
        .button:disabled:hover {
          background: inherit;
          border: inherit;
          color: inherit;
        }

        button.invert,
        .button.invert {
          background: transparent;
          color: #616161;
        }

        .details {
          border-top: 0.1em solid #d6d6d6;
          margin-top: 1em;
          padding-top: 1em;
          text-align: left;
        }
        .details.hidden {
          display: none;
        }

        .details-title {
          margin-top: 0;
          font-size: 0.9em;
          font-weight: 400;
        }

        .details-message {
          font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo,
            Courier, monospace;
          font-size: 0.8em;
        }
      `}</style>
    </main>
  );
};

InitError.propTypes = {
  nonce: PropTypes.string.isRequired,
  message: PropTypes.string
};
InitError.defaultProps = {
  message: null
};

export default InitError;
