import getConfig from '../get-config';
import { locales } from '../../../i18n';
import { getCookie, setCookie } from '../cookies';

const langIsValid = code => locales.indexOf(code) !== -1;

// Sets the user's language
export const setLanguageCookie = (lang, ctx) => {
  if (langIsValid(lang))
    setCookie('lng', lang, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      secure: true,
      httpOnly: false,
      ctx
    });
};

// Returns the current site language
export const getSiteLanguage = ctx => {
  // Look for ?locale= in query
  if (ctx && ctx.query && ctx.query.locale) {
    const { locale } = ctx.query;
    if (langIsValid(locale)) {
      setLanguageCookie(locale, ctx);
      return locale;
    }
  }

  // Look for a `lng` cookie
  const cookie = getCookie('lng', ctx);
  if (cookie && langIsValid(cookie)) return cookie;

  // Look for the default language
  const defaultLang = getConfig('SETTING_DEFAULT_LANGUAGE');
  if (defaultLang && langIsValid(defaultLang)) {
    setLanguageCookie(defaultLang, ctx);
    return defaultLang;
  }

  // Return English
  setLanguageCookie('en', ctx);
  return 'en';
};
