import styled from '@emotion/styled';

const Loading = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const Content = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Message = styled.span`
  margin-top: 1.3rem;
  display: block;
  font-size: 1.2rem;
  opacity: 0.7;
`;

export default { Loading, Content, Message };
