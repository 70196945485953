import PropTypes from 'prop-types';
import nextConfig from 'next/config';

const getConfig = (key, serverSide) => {
  if (!nextConfig()) return undefined;
  const { publicRuntimeConfig, serverRuntimeConfig } = nextConfig();
  let value = serverSide ? serverRuntimeConfig[key] : publicRuntimeConfig[key];

  // Convert string booleans into actual booleans
  if (value === 'true') return true;
  if (value === 'false') return false;

  // Replace any values
  if (typeof value === 'string') {
    value = value.replace(/\\n/g, '\n'); // replace '\n' from string to actual character
  }

  return value;
};

getConfig.propTypes = {
  key: PropTypes.string.isRequired
};

export default getConfig;
