const locales = {
  en: 'English'
};

module.exports = {
  locales: Object.keys(locales),
  fullLocales: locales,
  defaultLocale: process.env.SETTING_DEFAULT_LANGUAGE || 'en',
  localeDetection: false,
  loader: false,
  pages: {
    '*': ['common']
  }
};
