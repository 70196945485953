/* eslint-disable import/prefer-default-export */
// This file contains prop types for components that share the same
// props within them, such as the multiple layout variations of article cards
// or passing props from getInitialProps() into a different component. It
// helps to reduce data redundancy between components if the API is updated.
import PropTypes from 'prop-types';

// Initial props
export const initialPropTypes = {
  locals: PropTypes.shape({
    nonce: PropTypes.string
  }),
  lang: PropTypes.string.isRequired,
  cookiePrefs: PropTypes.objectOf(PropTypes.number)
};

// People
export const creatorPropTypes = {
  creator_given_name: PropTypes.string,
  creator_middle_name: PropTypes.string,
  creator_family_name: PropTypes.string,
  creator_organization_name: PropTypes.string,
  creator_name_type: PropTypes.string,
  creator_suffix: PropTypes.string,
  creator_isni: PropTypes.string,
  creator_orcid: PropTypes.string,
  creator_ror: PropTypes.string,
  creator_position: PropTypes.string,
  creator_institutional_relationship: PropTypes.arrayOf(PropTypes.string)
};
export const contributorPropTypes = {
  contributor_given_name: PropTypes.string,
  contributor_middle_name: PropTypes.string,
  contributor_family_name: PropTypes.string,
  contributor_organization_name: PropTypes.string,
  contributor_name_type: PropTypes.string,
  contributor_suffix: PropTypes.string,
  contributor_isni: PropTypes.string,
  contributor_orcid: PropTypes.string,
  contributor_ror: PropTypes.string,
  contributor_position: PropTypes.string,
  contributor_institutional_relationship: PropTypes.arrayOf(PropTypes.string)
};
export const editorPropTypes = {
  editor_given_name: PropTypes.string,
  editor_middle_name: PropTypes.string,
  editor_family_name: PropTypes.string,
  editor_organization_name: PropTypes.string,
  editor_name_type: PropTypes.string,
  editor_suffix: PropTypes.string,
  editor_isni: PropTypes.string,
  editor_orcid: PropTypes.string,
  editor_ror: PropTypes.string,
  editor_position: PropTypes.string,
  editor_institutional_relationship: PropTypes.arrayOf(PropTypes.string)
};

// Card
export const cardPropTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  resource_type: PropTypes.arrayOf(PropTypes.string),
  abstract: PropTypes.string,
  description: PropTypes.string, // used by `Works`
  creator: PropTypes.arrayOf(PropTypes.shape(creatorPropTypes)),
  thumbnail_url: PropTypes.string,
  layout: PropTypes.number,
  width: PropTypes.number,
  link: PropTypes.string
};

// Highlights
export const highlightPropTypes = {
  explore_collections: PropTypes.arrayOf(PropTypes.shape(cardPropTypes)),
  featured_works: PropTypes.arrayOf(PropTypes.shape(cardPropTypes)),
  recent_works: PropTypes.arrayOf(PropTypes.shape(cardPropTypes))
};

// File
export const filePropTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  display_name: PropTypes.string, // added in the getWork() action
  mimetype: PropTypes.string,
  license: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string
    })
  ),
  thumbnail_url: PropTypes.string,
  date_uploaded: PropTypes.string,
  current_visibility: PropTypes.string,
  embargo_release_date: PropTypes.string,
  lease_expiration_date: PropTypes.string,
  size: PropTypes.number,
  download_link: PropTypes.string
};

// Work metrics
export const workMetricsPropTypes = {
  views: PropTypes.number,
  downloads: PropTypes.number
};

// Work file
export const workFilePropTypes = {
  has_private_files: PropTypes.bool,
  has_registered_files: PropTypes.bool,
  has_public_files: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(filePropTypes))
};

// Work
export const workPropTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  related_url: PropTypes.arrayOf(PropTypes.string),
  work_type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  alternative_title: PropTypes.arrayOf(PropTypes.string),
  resource_type: PropTypes.arrayOf(PropTypes.string),
  visibility: PropTypes.string,
  workflow_status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  creator: PropTypes.arrayOf(PropTypes.shape(creatorPropTypes)),
  contributor: PropTypes.arrayOf(PropTypes.shape()), // TODO
  editor: PropTypes.arrayOf(PropTypes.shape()), // TODO
  cname: PropTypes.string,
  abstract: PropTypes.string,
  date_published: PropTypes.arrayOf(PropTypes.string),
  institution: PropTypes.arrayOf(PropTypes.string),
  organisational_unit: PropTypes.arrayOf(PropTypes.string),
  project_name: PropTypes.arrayOf(PropTypes.string),
  funder: PropTypes.arrayOf(
    PropTypes.shape({
      funder_name: PropTypes.string,
      funder_position: PropTypes.string
    })
  ),
  publisher: PropTypes.arrayOf(PropTypes.string),
  date_accepted: PropTypes.arrayOf(PropTypes.string),
  date_submitted: PropTypes.string,
  official_url: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.arrayOf(PropTypes.string),
  license: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    })
  ),
  rights_statement: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    })
  ),
  rights_holder: PropTypes.arrayOf(PropTypes.string),
  doi: PropTypes.arrayOf(PropTypes.string),
  alternate_identifier: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      position: PropTypes.number
    })
  ),
  peer_reviewed: PropTypes.bool,
  keywords: PropTypes.arrayOf(PropTypes.string),
  dewey: PropTypes.arrayOf(PropTypes.string),
  library_of_congress_classification: PropTypes.arrayOf(PropTypes.string),
  additional_info: PropTypes.arrayOf(PropTypes.string),
  related_identifier: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      relationship: PropTypes.string,
      position: PropTypes.number
    })
  ),
  thumbnail_url: PropTypes.string,
  download_link: PropTypes.string,
  version: PropTypes.arrayOf(PropTypes.string),
  duration: PropTypes.arrayOf(PropTypes.string),
  pagination: PropTypes.arrayOf(PropTypes.string),
  series_name: PropTypes.arrayOf(PropTypes.string),
  issue: PropTypes.arrayOf(PropTypes.string),
  volume: PropTypes.arrayOf(PropTypes.string),
  material_media: PropTypes.arrayOf(PropTypes.string),
  edition: PropTypes.arrayOf(PropTypes.string),
  journal_title: PropTypes.arrayOf(PropTypes.string),
  book_title: PropTypes.arrayOf(PropTypes.string),
  article_number: PropTypes.arrayOf(PropTypes.string),
  eissn: PropTypes.arrayOf(PropTypes.string),
  issn: PropTypes.arrayOf(PropTypes.string),
  isbn: PropTypes.arrayOf(PropTypes.string),
  qualification_name: PropTypes.arrayOf(PropTypes.string),
  qualification_level: PropTypes.arrayOf(PropTypes.string),
  alternative_journal_title: PropTypes.arrayOf(PropTypes.string),
  place_of_publication: PropTypes.arrayOf(PropTypes.string),
  funder_project_reference: PropTypes.arrayOf(PropTypes.string),
  event_title: PropTypes.arrayOf(PropTypes.string),
  event_location: PropTypes.arrayOf(PropTypes.string),
  event_date: PropTypes.arrayOf(PropTypes.string),
  related_exhibition: PropTypes.arrayOf(PropTypes.string),
  related_exhibition_date: PropTypes.arrayOf(PropTypes.string),
  related_exhibition_venue: PropTypes.arrayOf(PropTypes.string),
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      title: PropTypes.string
    })
  ),
  files: PropTypes.shape(workFilePropTypes).isRequired,
  is_scholarly: PropTypes.bool, // added in the getWork() action
  manifest_url: PropTypes.string, // added in the getWork() action
  metrics: PropTypes.shape(workMetricsPropTypes) // added in the getWork() action
};

// Tree
export const treePropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.shape({
    collection_id: PropTypes.string,
    volume: PropTypes.string,
    issue: PropTypes.string
  })
});

// Collection
export const collectionPropTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnail_url: PropTypes.string,
  works: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape(workPropTypes))
  }),
  journal_data: PropTypes.arrayOf(PropTypes.object) // added in the getCollection() action
};

// Search
export const searchPropTypes = {
  results: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape(workPropTypes))
  }).isRequired,
  facets: PropTypes.objectOf(PropTypes.objectOf(PropTypes.number))
};

// Shared layer tenants (from Google Storage bucket)
export const slTenantPropTypes = {
  display_name: PropTypes.string.isRequired,
  cname: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  logo: PropTypes.string
};

// Page Metadata
export const pageMetadataPropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  work: PropTypes.shape(workPropTypes)
};

// Cookie Provider
export const cookieProviderPropTypes = {
  name: PropTypes.string.isRequired,
  toggleable: PropTypes.bool.isRequired,
  detail: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      expiry: PropTypes.shape({
        type: PropTypes.string.isRequired,
        count: PropTypes.number
      }).isRequired,
      type: PropTypes.string.isRequired
    })
  )
};

// Cookie Section
export const cookieSectionPropTypes = {
  name: PropTypes.string.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape(cookieProviderPropTypes))
};

// Cookie Preferences
export const cookiePreferencesPropTypes = {
  allow_all: PropTypes.number,
  google_analytics: PropTypes.number
};

// User
export const userPropTypes = {
  email: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  type: PropTypes.arrayOf(PropTypes.string)
};
