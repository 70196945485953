export default [
  // Home (first screen)
  {
    name: 'home',
    providers: [
      {
        name: 'allow_all',
        toggleable: true
      }
    ],
    hideInNav: true
  },

  // Essential Cookies
  {
    name: 'essential_cookies',
    providers: [
      {
        name: 'essential_cookies',
        toggleable: false,
        detail: [
          {
            name: 'cookie_preferences',
            expiry: { type: 'year', count: 1 },
            type: 'http_cookie'
          },
          {
            name: '_traefikaffinity',
            expiry: { type: 'session' },
            type: 'http_cookie'
          },
          {
            name: 'next-i18next',
            expiry: { type: 'session' },
            type: 'http_cookie'
          },
          {
            name: '_hyku_session',
            expiry: { type: 'session' },
            type: 'http_cookie'
          },
          {
            name: 'jwt',
            expiry: { type: 'week', count: 1 },
            type: 'http_cookie'
          },
          {
            name: 'refresh',
            expiry: { type: 'week', count: 1 },
            type: 'http_cookie'
          }
        ]
      }
    ]
  },

  // Performance & Testing
  {
    name: 'performance_and_testing',
    providers: []
  },

  // Analytics & Tracking
  {
    name: 'analytics_and_tracking',
    providers: [
      {
        name: 'google_analytics',
        toggleable: true,
        detail: [
          {
            name: '_ga',
            expiry: { type: 'year', count: 2 },
            type: 'http_cookie'
          },
          {
            name: '_gid',
            expiry: { type: 'hour', count: 24 },
            type: 'http_cookie'
          },
          {
            name: '_gat',
            expiry: { type: 'minute', count: 1 },
            type: 'http_cookie'
          }
        ]
      }
    ]
  },

  // Advertising
  {
    name: 'advertising',
    providers: []
  }
];
