import Cookies from 'cookies';
import cookieCutter from 'cookie-cutter';
import getConfig from '../get-config';

export const getCookie = (key, ctx) => {
  if (ctx && ctx.req && ctx.res) {
    const cookies = new Cookies(ctx.req, ctx.res);
    return cookies.get(key);
  }

  if (process.browser) return cookieCutter.get(key);

  return null;
};

export const setCookie = (key, value, option = {}) => {
  try {
    const { ctx, ...options } = option;
    if (getConfig('IS_DEV')) options.secure = false;

    if (ctx && ctx.req && ctx.res) {
      const cookies = new Cookies(ctx.req, ctx.res, { secure: true });
      return cookies.set(key, value, options);
    }

    if (process.browser) {
      return cookieCutter.set(key, value, options);
    }

    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};
