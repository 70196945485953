/* eslint-disable import/prefer-default-export */
import getAbsoluteUrl from '../../utils/get-absolute-url';
import getConfig from '../../utils/get-config';
import HTTPRequest from '../../utils/http-request/http-request';
import RepositoryActionTypes from './types';

export const getRepositoryData = ctx => async dispatch => {
  try {
    // Pull the repository data from the API
    const [repositoryData] = await HTTPRequest({
      method: 'get',
      url: `?name=${getConfig('CNAME')}`,
      uuid: null, // we don't have the `uuid` at this point
      ctx
    });
    const data = {
      ...repositoryData,
      site_url: `${ctx.req.protocol}://${ctx.req.get('host')}`
    };

    // Append the site url to the data
    data.site_url = getAbsoluteUrl(ctx.req).origin;

    // Make sure we have settings
    if (!data.settings) data.settings = {};

    // Dispatch the Thunk event with the data
    dispatch({
      type: RepositoryActionTypes.GET_REPOSITORY_DATA,
      payload: data
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};
