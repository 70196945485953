import React from 'react';
import PropTypes from 'prop-types';
import Styles from './heading.styles';

const Heading = ({
  el = 'h1',
  design,
  id,
  className,
  css,
  'data-testid': testId,
  line,
  children
}) => {
  return (
    <Styles.Heading
      as={el}
      design={design || el}
      id={id}
      data-testid={testId}
      className={className}
      css={css}
    >
      {line ? <Styles.Line>{children}</Styles.Line> : children}
    </Styles.Heading>
  );
};

Heading.propTypes = {
  el: PropTypes.string,
  design: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  css: PropTypes.string,
  'data-testid': PropTypes.string,
  line: PropTypes.bool,
  children: PropTypes.node.isRequired
};
Heading.defaultProps = {
  el: 'h1',
  design: null,
  id: null,
  className: null,
  css: null,
  'data-testid': null,
  line: false
};

export default Heading;
