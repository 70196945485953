import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import Icon from '../icon';
import Styles from './loading.styles';

const Loading = ({ message, className }) => {
  const { t } = useTranslation('common');

  return (
    <Styles.Loading className={className} data-testid='loading'>
      <Styles.Content>
        <Icon
          icon='spinner'
          aria-label={message ? null : t('common.loading')}
          spin
        />
        {message && <Styles.Message>{message}</Styles.Message>}
      </Styles.Content>
    </Styles.Loading>
  );
};

Loading.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string
};

Loading.defaultProps = {
  message: null,
  className: null
};

export default Loading;
