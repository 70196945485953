import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Heading from '../heading';
import UIIcon from '../icon';
import UILoading from '../loading';

const Player = styled.div`
  padding: ${props => (props.$minimised ? '1rem 2rem' : '2rem')};
  border-radius: 0.5rem;
  background: ${props => props.theme.site.background_color};
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.3);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Heading)`
  margin-bottom: 0;
  margin-right: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40vw;
`;

const Controls = styled.ul`
  display: flex;
  margin-right: -1rem;
  align-items: center;
`;
const Control = styled.li``;

const Button = styled.button`
  padding: 1rem;
  opacity: 0.7;
  height: 3.5rem;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const Icon = styled(UIIcon)`
  font-size: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
`;

const Loading = styled(UILoading)`
  width: 100px;
  height: 50px;
`;

const PlayerArea = styled.div`
  margin-top: 1.5rem;

  ${props =>
    props.$minimised &&
    css`
      height: 0;
      overflow: hidden;
      position: absolute;
    `}
`;

const Video = styled.video`
  max-width: 400px;
  min-width: 100px;
  margin: 0 auto;
  display: block;
  height: auto;
  max-height: 50vh;
`;

const Audio = styled.audio`
  width: 400px;
  display: block;
`;

const Container = styled.section`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 9999;

  @media (max-width: 500px) {
    bottom: 0;
    right: 0;
    width: 100%;

    ${Player} {
      border-radius: 0;
    }

    ${Title} {
      max-width: 100%;
    }
  }
`;

export default {
  Container,
  Player,
  Header,
  Title,
  Controls,
  Control,
  Button,
  Icon,
  Loading,
  PlayerArea,
  Video,
  Audio
};
