import React from 'react';
import PropTypes from 'prop-types';
import I18nProvider from '../../contexts/i18n';
import ThemeProvider from '../../contexts/theme';
import MediaProvider from '../../contexts/media';
import { HistoryProvider } from '../../contexts/history';
import RefreshListener from '../refresh-listener';
import NonceProvider from '../../contexts/nonce';
import CookiePrefsProvider from '../../contexts/cookie-prefs';
import { initialPropTypes } from '../../proptypes';
import UserProvider from '../../contexts/user';

const Providers = ({ initialProps, children }) => {
  const {
    lang,
    user,
    locals: { nonce },
    cookiePrefs
  } = initialProps;

  return (
    <I18nProvider lang={lang}>
      <NonceProvider nonce={nonce}>
        <UserProvider user={user}>
          <ThemeProvider>
            <MediaProvider>
              <HistoryProvider>
                <CookiePrefsProvider defaultPrefs={cookiePrefs}>
                  <RefreshListener>{children}</RefreshListener>
                </CookiePrefsProvider>
              </HistoryProvider>
            </MediaProvider>
          </ThemeProvider>
        </UserProvider>
      </NonceProvider>
    </I18nProvider>
  );
};

Providers.propTypes = {
  initialProps: PropTypes.shape(initialPropTypes).isRequired,
  children: PropTypes.node.isRequired
};

export default Providers;
