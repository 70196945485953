/* eslint-disable no-console */
const formatError = error => {
  if (typeof error === 'object') {
    if (error.status && error.status === 404) return '404';
    return error.message ? error.message : JSON.stringify(error);
  }
  if (typeof error === 'string') return error;

  if (process.env.NODE_ENV !== 'test')
    console.error(`Unsupported error type: ${typeof error}\n${error}`);
  return error;
};

export default formatError;
