import PropTypes from 'prop-types';
import moment from 'moment';
import { getSiteLanguage } from '../site-language';
import { getRepositoryData } from '../../redux/repository/actions';
import { getCookie } from '../cookies';
import formatError from '../format-error';
import redirect from '../redirect';
import { getTenantsData } from '../../redux/tenants/actions';
import { getPrefs } from '../../contexts/cookie-prefs/_utils';
import getConfig from '../get-config';
import login from '../../actions/login';

const initialLoad = async (ctx, store) => {
  // The return data from this function
  // (so it can be returned by catch() incase something fails)
  const returnData = {
    locals: {},
    lang: undefined,
    cookiePrefs: {},
    user: null
  };

  try {
    // Get locals from the Express server
    if (ctx && ctx.res && ctx.res.locals) returnData.locals = ctx.res.locals;

    // Localise Moment
    moment.locale(getSiteLanguage(ctx));

    // Get language
    returnData.lang = getSiteLanguage(ctx);

    // Get cookie prefs
    returnData.cookiePrefs = getPrefs(ctx);

    // Do we need authentication?
    if (getConfig('SETTING_REQUIRE_PASSCODE') && !process.browser) {
      const authKey = getCookie('auth_key', ctx);
      if (authKey !== process.env.PASSCODE_TEXT) {
        if (ctx.pathname !== '/auth' && ctx.pathname !== '/_error')
          redirect(`/auth?from=${ctx.asPath}`, ctx);
        return Promise.resolve(returnData);
      }
    }

    // Do we already have the core repository data loaded?
    if (store.getState().repository.data)
      return Promise.resolve({ ...returnData });

    // Get the core repository data
    await store.dispatch(getRepositoryData(ctx));
    await store.dispatch(getTenantsData(ctx, store)); // Shared layer only

    // Login the user if possible
    returnData.user = await login({ ...ctx, store });

    // All done
    return Promise.resolve({ ...returnData });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return Promise.resolve({ ...returnData, err: formatError(error) });
  }
};

initialLoad.propTypes = {
  ctx: PropTypes.shape({
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired
    }).isRequired
  }).isRequired
};

export default initialLoad;
