import React from 'react';
import { useNonce } from '../../contexts/nonce';

const NoJS = () => {
  const { nonce } = useNonce();

  return (
    <noscript>
      <style nonce={nonce}>
        {`
        
          .nojs-show, .nojs-show-inherit {
            display: inherit !important;
          }
          .nojs-show-table-row {
            display: table-row !important;
          }
          .nojs-hide {
            display: none !important;
          }
        `}
      </style>
    </noscript>
  );
};

export default NoJS;
