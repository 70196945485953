import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
  ${props =>
    props.spin &&
    css`
      animation: icon-spin 2s linear infinite;

      @keyframes icon-spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(1turn);
          transform: rotate(1turn);
        }
      }
    `}
`;

export default { Icon };
