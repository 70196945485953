import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

// The context
const MediaContext = createContext({});

// The component
const MediaProvider = ({ children }) => {
  const [media, _setMedia] = useState({
    loading: false,
    url: null,
    mimetype: null,
    title: null
  });

  const setMedia = ({ url, mimetype, title, loading }) => {
    _setMedia({ ...media, loading, url, mimetype, title });
  };

  const closeMedia = () => {
    _setMedia({
      ...media,
      url: null,
      mimetype: null,
      title: null,
      loading: false
    });
  };

  return (
    <MediaContext.Provider value={{ ...media, setMedia, closeMedia }}>
      {children}
    </MediaContext.Provider>
  );
};

// The hook
export const useMedia = () => useContext(MediaContext);

MediaProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default MediaProvider;
