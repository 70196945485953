import { getCookie, setCookie } from '../../utils/cookies';
import sections from './_sections';

// Returns the default preferences object
export const getDefaultPrefs = (status = 1) => {
  return (
    sections
      // return only providers
      .map(section => section.providers)
      // return only providers that have data
      .reduce((acc, curr) => {
        return curr.length > 0 ? [...acc, ...curr] : acc;
      }, [])
      // return only providers that are toggleable
      .reduce((acc, curr) => {
        return curr.toggleable ? { ...acc, [curr.name]: status } : acc;
      }, {})
  );
};

// Returns a user's preferences
export const getPrefs = ctx => {
  try {
    const preferences = getCookie('cookie_preferences', ctx);
    return preferences
      ? JSON.parse(decodeURIComponent(preferences))
      : getDefaultPrefs();
  } catch (err) {
    return getDefaultPrefs();
  }
};

// Returns true if the provided data matches `allow_all`
export const isAllowingAll = prefs => {
  const newPrefs = { ...prefs, allow_all: -1, accepted: -1 };
  const defaults = { ...getDefaultPrefs(1), allow_all: -1, accepted: -1 };
  return JSON.stringify(newPrefs) === JSON.stringify(defaults) ? 1 : 0;
};

// Saves a user's preferences
export const savePrefs = prefs => {
  setCookie('cookie_preferences', JSON.stringify({ ...prefs }), {
    path: '/',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    secure: true
  });
};
