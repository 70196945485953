/* eslint-disable import/prefer-default-export */
// This is for the Shared Layer, to know which tenants are associated with the site.
import getConfig from '../../utils/get-config';
import HTTPRequest from '../../utils/http-request';
import TenantsActionTypes from './types';

// Ideally this action won't be around for long.
export const getTenantsData = (ctx, store) => async dispatch => {
  try {
    // If we are not on the Shared Layer, skip this.
    if (!getConfig('IS_SHARED_LAYER')) return Promise.resolve();

    // Pull the repository config from the store
    const { repository } = store.getState();

    // Get the data
    const data = await HTTPRequest({
      method: 'get',
      url: `${getConfig('STATIC_FILES_URL')}/tenants.json`,
      uuid: repository.data.tenant,
      ctx
    });

    // Dispatch the Thunk event with the data
    dispatch({
      type: TenantsActionTypes.GET_TENANTS_DATA,
      payload: data
    });

    return Promise.resolve();
  } catch (err) {
    // There is a chance this is a new SL tenant, so there might not be a .json file yet.
    // We don't want the site to completely break, so we'll just set the object to be empty.
    dispatch({
      type: TenantsActionTypes.GET_TENANTS_DATA,
      payload: {}
    });
    return Promise.resolve();
  }
};
