import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

// The context
const NonceContext = createContext({});

// The component
const NonceProvider = ({ nonce, children }) => {
  const [appNonce] = useState(nonce);

  return (
    <NonceContext.Provider value={{ nonce: appNonce }}>
      {children}
    </NonceContext.Provider>
  );
};

// The hook
export const useNonce = () => useContext(NonceContext);

NonceProvider.propTypes = {
  nonce: PropTypes.string,
  children: PropTypes.node.isRequired
};
NonceProvider.defaultProps = {
  nonce: undefined
};

export default NonceProvider;
