import { config, library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebookSquare,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
  faSearch,
  faCompass,
  faUser,
  faFileAlt,
  faFileInvoice,
  faBook,
  faBookOpen,
  faChalkboardTeacher,
  faChartBar,
  faMap,
  faFileImage,
  faFolder,
  faNewspaper,
  faFileContract,
  faGraduationCap,
  faTv,
  faChevronRight,
  faSpinner,
  faCaretLeft,
  faCaretRight,
  faChevronDown,
  faChevronUp,
  faTimes,
  faDownload,
  faFile,
  faFileArchive,
  faFileAudio,
  faFileVideo,
  faFileCode,
  faFileWord,
  faCalendar,
  faFilePdf,
  faFilePowerpoint,
  faFileCsv,
  faQuoteRight,
  faShareAlt,
  faEye,
  faPlus,
  faMinus,
  faChevronLeft,
  faExternalLinkAlt,
  faCodeBranch,
  faCogs,
  faCheck,
  faBars,
  faGlobeEurope,
  faShoppingBag,
  faPencilAlt,
  faTrash,
  faPlayCircle
} from '@fortawesome/free-solid-svg-icons';

// Config
config.autoAddCss = false; // resolves CSP issues

library.add(
  fab,
  faSearch,
  faCompass,
  faUser,
  faFileAlt,
  faFileInvoice,
  faBook,
  faBookOpen,
  faChalkboardTeacher,
  faChartBar,
  faMap,
  faFileImage,
  faFolder,
  faNewspaper,
  faFileContract,
  faGraduationCap,
  faTv,
  faChevronRight,
  faSpinner,
  faCaretLeft,
  faCaretRight,
  faChevronDown,
  faChevronUp,
  faTimes,
  faDownload,
  faFile,
  faFileArchive,
  faFileAudio,
  faFileVideo,
  faFileImage,
  faFileCode,
  faFileWord,
  faCalendar,
  faFilePdf,
  faFilePowerpoint,
  faFileAlt,
  faFileCsv,
  faQuoteRight,
  faShareAlt,
  faFacebookSquare,
  faTwitter,
  faEye,
  faPlus,
  faMinus,
  faChevronLeft,
  faExternalLinkAlt,
  faCodeBranch,
  faCogs,
  faCheck,
  faBars,
  faGlobeEurope,
  faShoppingBag,
  faPencilAlt,
  faTrash,
  faPlayCircle
);
