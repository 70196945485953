import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import sections from './_sections';
import { getDefaultPrefs, isAllowingAll, savePrefs as save } from './_utils';

const CookiePrefsContext = createContext({});

const CookiePrefsProvider = ({ defaultPrefs, children }) => {
  const [prefs, setPrefs] = useState(defaultPrefs);
  const [savedPrefs, setSavedPrefs] = useState(prefs);

  // Saves a user's prefs
  const savePrefs = () => {
    const saved = { ...prefs, accepted: 1 };
    setPrefs(saved);
    setSavedPrefs(saved);
    save(saved);
  };

  // Toggle all prefs to the default (either all enabled or all disabled)
  const toggleAll = val =>
    setPrefs({ ...getDefaultPrefs(val), accepted: prefs.accepted });

  // Returns a single pref
  const getPref = providerName => prefs[providerName];

  // Sets a single pref
  const setPref = (providerName, val) => {
    if (providerName === 'allow_all') toggleAll(val);
    else {
      const newPrefs = { ...prefs, [providerName]: val };
      setPrefs({ ...newPrefs, allow_all: isAllowingAll(newPrefs) });
    }
  };

  return (
    <CookiePrefsContext.Provider
      value={{ sections, savedPrefs, getPref, setPref, savePrefs }}
    >
      {children}
    </CookiePrefsContext.Provider>
  );
};

export const useCookiePrefs = () => useContext(CookiePrefsContext);

CookiePrefsProvider.propTypes = {
  defaultPrefs: PropTypes.objectOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired
};

export default CookiePrefsProvider;
