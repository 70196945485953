import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Reloads the tab if a change to the localStorage is detected
const RefreshListener = ({ children }) => {
  // Reload the tab
  const refresh = e => {
    if (e.key === 'refresh') window.location.reload();
  };

  // Listen for a change in localStorage
  useEffect(() => {
    window.addEventListener('storage', refresh);

    return () => {
      window.removeEventListener('storage', refresh);
      window.localStorage.removeItem('refresh');
    };
  }, []);

  return children;
};

RefreshListener.propTypes = {
  children: PropTypes.node.isRequired
};

export default RefreshListener;
