import { createStore, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import reducers from './root-reducer';

// create a makeStore function
const makeStore = () =>
  createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

// export an assembled wrapper
const wrapper = createWrapper(makeStore, { debug: false });

export default wrapper;
