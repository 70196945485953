// This file is included in ./index.js
import { css } from '@emotion/react';

const nprogress = theme => css`
  #nprogress {
    pointer-events: none;

    .bar {
      background: ${theme.loading_bar.foreground_color};
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.3rem;
    }

    /* Fancy blur effect */
    .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 10rem;
      height: 100%;
      box-shadow: 0 0 1rem ${theme.loading_bar.foreground_color},
        0 0 0.5rem ${theme.loading_bar.foreground_color};
      opacity: 1;
      transform: rotate(3deg) translate(0px, -4px);
    }
  }
`;

export default nprogress;
