import styled from '@emotion/styled';

const Heading = styled.h1`
  position: relative;
  font-size: ${props =>
    (props.design === 'h1' && '3rem') ||
    (props.design === 'h2' && '2rem') ||
    (props.design === 'h3' && '1.8rem') ||
    (props.design === 'h4' && '1.6rem') ||
    (props.design === 'h5' && '1.4rem') ||
    'inherit'};
  line-height: ${props =>
    (props.design === 'h1' && '3.8rem') ||
    (props.design === 'h2' && '2.8rem') ||
    (props.design === 'h3' && '2.6rem') ||
    (props.design === 'h4' && '2.4rem') ||
    (props.design === 'h5' && '2.2rem') ||
    'inherit'};
  margin-bottom: 1.2rem;
  font-weight: 500;
`;

const Line = styled.span`
  background: ${props => props.theme.site.background_color};
  text-transform: uppercase;
  padding-right: 1.5rem;

  &:after {
    content: '';
    position: absolute;
    background: ${props => props.theme.site.line_color};
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 0.1rem;
    z-index: -1;
  }
`;

export default { Heading, Line };
