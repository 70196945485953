import React, { useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMedia } from '../../contexts/media';
import Styles from './media-player.styles';

const MediaPlayer = () => {
  const ref = useRef();
  const { t } = useTranslation('common');
  const { loading, url, title, mimetype, closeMedia } = useMedia();
  const [minimised, setMinimised] = useState(false);

  // Move focus to the close button on mount
  useEffect(() => {
    if (ref && ref.current) ref.current.focus();
  }, [url, loading]);

  if (!url && !loading) return null;

  return (
    <Styles.Container data-testid='media-player'>
      <Styles.Player $minimised={minimised}>
        <Styles.Header>
          {title && <Styles.Title design='h4'>{title}</Styles.Title>}

          <Styles.Controls>
            <Styles.Control>
              <Styles.Button
                title={t(
                  minimised
                    ? 'media_player.maximise_button'
                    : 'media_player.minimise_button'
                )}
                aria-label={t(
                  minimised
                    ? 'media_player.maximise_button'
                    : 'media_player.minimise_button'
                )}
                onClick={() => setMinimised(!minimised)}
              >
                <Styles.Icon icon={minimised ? 'plus' : 'minus'} />
              </Styles.Button>
            </Styles.Control>
            <Styles.Control>
              <Styles.Button
                title={t('media_player.close_button')}
                aria-label={t('media_player.close_button')}
                onClick={closeMedia}
                ref={ref}
              >
                <Styles.Icon icon='times' />
              </Styles.Button>
            </Styles.Control>
          </Styles.Controls>
        </Styles.Header>

        {loading ? (
          <Styles.Loading />
        ) : (
          <Styles.PlayerArea $minimised={minimised}>
            {mimetype.includes('video') ? (
              <Styles.Video controls autoPlay data-testid='media-video'>
                <source src={url} type={mimetype} />
                {t('media_player.video.unsupported_browser')}
              </Styles.Video>
            ) : (
              <Styles.Audio controls autoPlay data-testid='media-audio'>
                <source src={url} type={mimetype} />
                {t('media_player.audio.unsupported_browser')}
              </Styles.Audio>
            )}
          </Styles.PlayerArea>
        )}
      </Styles.Player>
    </Styles.Container>
  );
};

export default MediaPlayer;
