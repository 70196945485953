import React from 'react';
import PropTypes from 'prop-types';
import {
  ThemeProvider as EmotionThemeProvider,
  CacheProvider
} from '@emotion/react';
import createCache from '@emotion/cache';
import { mainTheme } from '../../themes';
import { useNonce } from '../nonce';
import GlobalStyle from '../../styles';

const ThemeProvider = ({ children }) => {
  const { nonce } = useNonce();

  const cache = createCache({
    key: 'repo',
    stylisPlugins: [],
    nonce
  });

  return (
    <CacheProvider value={cache}>
      <EmotionThemeProvider theme={mainTheme}>
        <GlobalStyle theme={mainTheme} />
        {children}
      </EmotionThemeProvider>
    </CacheProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
