import trimString from '../trim-string';

const isEmpty = val => {
  if (val === false) return false;
  if (!val) return true;

  if (typeof val === 'string' || val instanceof String) return !trimString(val);

  if (Array.isArray(val)) {
    if (val.length === 0) return true;
    return val.filter(item => !isEmpty(item)).length === 0;
  }

  if (typeof val === 'object') return Object.keys(val).length === 0;

  return false;
};

export default isEmpty;
