// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import nextConfig from 'next/config';

const { publicRuntimeConfig } = nextConfig();
const { SENTRY_DSN, IS_DEV } = publicRuntimeConfig;

Sentry.init({
  enabled: !IS_DEV,
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.05
});
