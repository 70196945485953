import { HYDRATE } from 'next-redux-wrapper';
import RepositoryActionTypes from './types';

const INITIAL_STATE = {
  data: null
};

const RepositoryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return { ...state, ...payload.repository };
    case RepositoryActionTypes.GET_REPOSITORY_DATA:
      return {
        ...INITIAL_STATE,
        data: payload
      };
    default:
      return state;
  }
};

export default RepositoryReducer;
