import { combineReducers } from 'redux';
import RepositoryReducer from './repository/reducer';
import TenantsReducer from './tenants/reducer';

const rootReducer = combineReducers({
  repository: RepositoryReducer,
  tenants: TenantsReducer
});

export default rootReducer;
