/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import NProgress from 'nprogress';
import wrapper from '../redux/store';
import InitError from '../components/init-error';
import initialLoad from '../utils/initial-load';
import MediaPlayer from '../components/media-player';
import NoJS from '../components/no-js';
import Providers from '../components/providers';
import { initialPropTypes } from '../proptypes';
import '../lib/fontawesome';

// Progress Bar
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = ({ Component, pageProps, initData, err }) => {
  return initData.err ? (
    <InitError nonce={initData.locals.nonce} message={initData.err} />
  ) : (
    <Providers initialProps={initData}>
      <NoJS />
      <MediaPlayer />
      <Component {...pageProps} err={err} />
    </Providers>
  );
};

MyApp.getInitialProps = wrapper.getInitialAppProps(
  store => async appContext => {
    // Load the initial site data required for SSR
    const initData = await initialLoad(appContext.ctx, store);

    // Call the page's `getInitialProps` function, passing in everything we also
    // received from the initialLoad() function -- meaning we can access the {user}
    // and {store} object from the {ctx} object.
    let pageProps = {};
    if (appContext.Component.getInitialProps)
      pageProps = await appContext.Component.getInitialProps({
        ...appContext.ctx,
        ...initData,
        store
      });

    // Return the data
    return { pageProps, initData };
  }
);

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
  initData: PropTypes.shape(initialPropTypes).isRequired,
  initErr: PropTypes.any,
  err: PropTypes.any
};
MyApp.defaultProps = {
  initErr: null,
  err: null
};

export default wrapper.withRedux(MyApp);
