import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const HistoryContext = createContext({});

// Context
export const HistoryProvider = ({ children }) => {
  const { asPath } = useRouter();
  const [history, setHistory] = useState([]);
  const [previous, setPrevious] = useState(null);

  const LIMIT = 5;
  const BLACKLIST = ['/logout'];

  // Route has changed
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const newHistory = [...history];

    // Push the route, if it's actually different and not in the blacklist
    if (asPath !== newHistory[0] && !BLACKLIST.includes(asPath)) {
      newHistory.unshift(asPath);

      // Make sure the array is only of length N, for memory purposes
      while (newHistory.length > LIMIT) newHistory.pop();

      // Update the array
      setHistory(newHistory);

      // Get the previous page
      for (let i = 0; i < history.length; i += 1)
        if (history[i] !== asPath) return setPrevious(history[i]);
    }
  }, [asPath]);

  // Return the provider
  return (
    <HistoryContext.Provider value={{ history, previous }}>
      {children}
    </HistoryContext.Provider>
  );
};

// Hook
export const useHistory = () => useContext(HistoryContext);

HistoryProvider.propTypes = {
  children: PropTypes.node.isRequired
};
