// This file is included in ./index.js
import { css } from '@emotion/react';

const global = theme => css`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    margin: 0;
    padding: 0;
    display: block;
    background: ${theme.site.background_color};
  }

  body,
  input,
  textarea,
  button,
  select,
  label,
  span,
  p {
    font-family: PingFang HK, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    color: ${theme.colors.primary};
  }

  body,
  input,
  textarea,
  button,
  select {
    font-size: 1.4rem;
  }

  i,
  em {
    font-style: italic;
  }
  strong {
    font-weight: 500;
  }

  a,
  .link {
    text-decoration: none;
    color: ${theme.colors.link};

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.link};
    }
  }

  body.tabbing {
    *:focus {
      box-shadow: 0 0 0 0.2rem #4d90fe;
      outline: none;
    }
  }

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export default global;
