import React from 'react';
import PropTypes from 'prop-types';
import Styles from './icon.styles';

// Returns a FontAwesome icon
const Icon = ({
  icon,
  weight = 'fas',
  'aria-label': ariaLabel,
  'data-tip': dataTip,
  spin,
  className
}) => {
  return (
    <Styles.Icon
      spin={spin}
      className={className}
      icon={[weight || 'fas', icon]}
      aria-label={ariaLabel}
      aria-hidden={ariaLabel ? 'false' : 'true'}
      data-tip={dataTip}
      title={ariaLabel}
      width='16'
      data-testid='icon'
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  weight: PropTypes.string,
  'aria-label': PropTypes.string,
  'data-tip': PropTypes.string,
  spin: PropTypes.bool,
  className: PropTypes.string
};

Icon.defaultProps = {
  weight: 'fas',
  'aria-label': null,
  'data-tip': null,
  spin: false,
  className: null
};

export default Icon;
