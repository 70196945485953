import React, { createContext, useContext, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import { userPropTypes } from '../../proptypes';

// The context
const UserContext = createContext({});

// The component
const UserProvider = ({ user, children }) => {
  const [data, setData] = useState(user || null);

  // Function to update the user
  const setUser = newUser => setData(newUser);

  // Set the user for Sentry
  if (data) Sentry.setUser({ email: data.email });
  else Sentry.configureScope(scope => scope.setUser(null));

  return (
    <UserContext.Provider value={{ user: data, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// The hook
export const useUser = () => useContext(UserContext);

UserProvider.propTypes = {
  user: PropTypes.shape(userPropTypes),
  children: PropTypes.node.isRequired
};
UserProvider.defaultProps = {
  user: null
};

export default UserProvider;
