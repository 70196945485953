/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import PropTypes from 'prop-types';
import NextTranslateProvider from 'next-translate/I18nProvider';
import config from '../../../i18n';
import isEmpty from '../../utils/is-empty';
import getConfig from '../../utils/get-config';
import mergeDeep from '../../utils/merge-deep';

const locales = {};

const I18nProvider = ({ lang, children }) => {
  if (isEmpty(locales)) {
    const overrides = JSON.parse(getConfig('TRANSLATION_OVERRIDES') || '{}');

    // Get each locale, and merge it with any overrides
    config.locales.forEach(locale => {
      const base = require(`../../locales/${locale}.json`);
      const merged = mergeDeep(
        base,
        overrides[locale] ? overrides[locale] : {}
      );

      locales[locale] = merged;
    });
  }

  return (
    <NextTranslateProvider lang={lang} namespaces={{ common: locales[lang] }}>
      {children}
    </NextTranslateProvider>
  );
};

I18nProvider.propTypes = {
  lang: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default I18nProvider;
