import { getCookie } from '../../utils/cookies';
import HTTPRequest from '../../utils/http-request';

const login = async ctx => {
  try {
    const { repository } = ctx.store.getState();

    // Check to see if we have a jwt and refresh cookie
    const jwt = getCookie('jwt', ctx);
    const refresh = getCookie('refresh', ctx);

    // One or both are missing
    if (!jwt || !refresh) return Promise.resolve();

    // Get the user
    const user = await HTTPRequest({
      method: 'POST',
      url: '/users/current',
      uuid: repository.data.tenant,
      ctx
    });

    return Promise.resolve(user);
  } catch (err) {
    return Promise.resolve();
  }
};

export default login;
